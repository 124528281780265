<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "Navigation",
  setup() {
    const scrolledNav = ref(false);
    const mobile = ref(false);
    const mobileNav = ref(false);
    const navLoaded = ref(false);
    const router = useRouter();
    const route = useRoute();


    const toggleMobileNav = () => {
      mobileNav.value = !mobileNav.value;
    };

    const updateScroll = () => {
      scrolledNav.value = window.scrollY > 50;
    };

    const checkScreen = () => {
      mobile.value = window.innerWidth <= 750;
      if (!mobile.value) {
        mobileNav.value = false;
      }
    };

    const handleNavigation = (event, sectionId) => {
  event.preventDefault();
  if (mobileNav.value) {
    mobileNav.value = false;
  }
  if (route.path === '/') {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  } else {
    router.push({ path: '/', hash: `#${sectionId}` }).then(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    });
  }
};

    onMounted(() => {
      window.addEventListener('resize', checkScreen);
      window.addEventListener('scroll', updateScroll);
      checkScreen();
      setTimeout(() => {
        navLoaded.value = true;
      }, 600);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkScreen);
      window.removeEventListener('scroll', updateScroll);
    });

    return {
      scrolledNav,
      mobile,
      mobileNav,
      toggleMobileNav,
      navLoaded,
      handleNavigation,
    };
  },
};
</script>

<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding">
        <a
          class="logo"
          href="#home"
        >NSA</a>
      </div>
      <ul v-show="!mobile" :class="['navigation', { 'nav-loaded': navLoaded }]">
      <li>
        <a
          class="link"
          href="#about"
          @click="handleNavigation($event, 'about')"
        >About</a>
      </li>
      <li>
        <a
          class="link"
          href="#projects"
          @click="handleNavigation($event, 'projects')"
        >Projects</a>
      </li>
      <li>
        <a
          class="link"
          href="#contact"
          @click="handleNavigation($event, 'contact')"
        >Contact</a>
      </li>

       <li>
          <router-link class="link" to="/blog">Blog</router-link>
        </li>

    </ul>
    <div class="icon">
  <i
    v-if="mobile"
    :class="mobileNav ? 'fa fa-times' : 'fa fa-bars'"
    @click="toggleMobileNav"
  />
</div>
<transition name="mobile-nav">
  <ul v-if="mobileNav" class="dropdown-nav">
    <li>
      <a class="link" href="#about" @click="handleNavigation($event, 'about')">About</a>
    </li>
    <li>
      <a class="link" href="#projects" @click="handleNavigation($event, 'projects')">Projects</a>
    </li>
    <li>
      <a class="link" href="#contact" @click="handleNavigation($event, 'contact')">Contact</a>
    </li>
    <li>
        <router-link class="link" to="/blog">Blog</router-link>
    </li>
  </ul>
</transition>

    </nav>
  </header>
</template>

<style scoped>
.navigation {
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.5s ease-out;
}

.nav-loaded {
  opacity: 1;
  transform: translateY(0);
}

.link {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.icon {
  display: none;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 750px) {
  .icon {
    display: block;
  }

  .navigation {
    display: none;
  }

  .dropdown-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 300;
  }

  .dropdown-nav li {
    margin-bottom: 20px;
  }

  .dropdown-nav .link {
    color: black;
    font-size: 24px;
    font-weight: bold;
  }

  ::v-deep .mobile-nav-enter-active,
  ::v-deep .mobile-nav-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  ::v-deep .mobile-nav-enter-from,
  ::v-deep .mobile-nav-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  ::v-deep .mobile-nav-enter-to,
  ::v-deep .mobile-nav-leave-from {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>



