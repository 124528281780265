<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import wordpressService from '../services/wordpressService';
import DOMPurify from 'dompurify';
import Navigation from './Navigation.vue';

export default {
  name: 'BlogPost',
  components: {
    Navigation,
  },
  setup() {
    const post = ref(null);
    const router = useRouter();
    const route = useRoute();

    const fetchPost = async () => {
      const postId = route.params.id;
      try {
        const fetchedPost = await wordpressService.getPostById(postId);
        post.value = {
          ...fetchedPost,
          title: {
            rendered: DOMPurify.sanitize(fetchedPost.title.rendered),
          },
          content: {
            rendered: DOMPurify.sanitize(fetchedPost.content.rendered),
          },
        };
      } catch (error) {
        console.error(`Error fetching post ${postId}:`, error);
      }
    };

    const goBack = () => {
      router.push({ name: 'BlogList' });
    };

    onMounted(() => {
      fetchPost();
    });

    return {
      post,
      goBack,
    };
  },
};
</script>

 <template>
 <Navigation />
  <div class="blog-post-container" v-if="post">
    <div class="back-button-wrapper">
      <button class="back-button" @click="goBack">← Back</button>
    </div>
    <div class="blog-post">
      <h1 v-html="post.title.rendered"></h1>
      <div class="post-content" v-html="post.content.rendered"></div>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<style scoped>
.blog-post-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  color: #f5f5f5;
}

.back-button-wrapper {
  position: absolute;
  top: 1rem;
  left: -150px;
}

.back-button {
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  background: linear-gradient(135deg, #00afea 0%, #0044cc 100%);
  color: #fff;
  border: none;
}

.back-button:hover {
  background: linear-gradient(135deg, #008fcc 0%, #003399 100%);
}

.blog-post {
  text-align: center;
}

.blog-post h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.post-content {
  text-align: left;
  line-height: 1.6;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .blog-post-container {
    margin: 1rem;
    padding: 0.5rem;
  }

  .blog-post {
    text-align: left;
  }

  .blog-post h1 {
    font-size: 1.5rem;
  }

  .post-content {
    font-size: 0.95rem;
  }

  .blog-post-container {
    margin: 1rem;
    padding: 0.5rem;
  }

  .back-button-wrapper {
    position: static;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .back-button {
    padding: 10px 15px;
    font-size: 0.9rem;
    width: auto;
    box-sizing: border-box;
  }
}
</style>
