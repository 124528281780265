<script setup>
import { useRouter } from 'vue-router';
import Navigation from '../components/Navigation.vue';

const router = useRouter();

const goBack = () => {
  router.push({ path: '/', hash: '#contact' });
};
</script>

<template>
  <Navigation />

   <!-- <div class="back-button">
      <router-link to="/#contact" >Back to Contact Page</router-link>
    </div> -->

    <div class="back-button-wrapper">
      <button class="back-button" @click="goBack">← Back</button>
    </div>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>Last updated: October 29, 2024</p>

    <section>
      <h2>1. Overview</h2>
      <p>Your privacy is important to us. This Privacy Policy explains how we handle any personal data we collect, in compliance with the General Data Protection Regulation (GDPR) and other relevant laws. We are committed to ensuring your information is secure and transparent in our practices.</p>
    </section>

    <section>
      <h2>2. Data Collection and Use</h2>
      <p>Currently, we do not store, collect, or share any personal data on our servers. If you use our contact form, your name, email address, and message are sent to us securely via EmailJS. This information is only used to respond to your inquiries and is not stored in our system beyond the initial response.</p>
    </section>

    <section>
      <h2>3. Third-Party Services</h2>
      <p>We use EmailJS to process form submissions, which handles data according to their own privacy policy, compliant with GDPR standards. We encourage you to review their policy for further details: <a href="https://www.emailjs.com/legal/privacy-policy/" target="_blank">EmailJS Privacy Policy</a>.</p>
    </section>

    <section>
      <h2>4. Your Rights</h2>
      <ul>
        <li><strong>Access:</strong> Request details of any data we may hold on you.</li>
        <li><strong>Rectification:</strong> Correct inaccurate or incomplete information.</li>
        <li><strong>Erasure:</strong> Request deletion of personal data where it is no longer necessary.</li>
        <li><strong>Objection:</strong> Object to the processing of your data, if applicable.</li>
      </ul>
      <p>To exercise these rights, please contact us at <a href="mailto:naeliasalas@gmail.com">naeliasalas@gmail.com</a>.</p>
    </section>

    <section>
      <h2>5. Changes to this Privacy Policy</h2>
      <p>We may occasionally update this policy. Changes will be posted on this page with an updated "last updated" date.</p>
    </section>

    <section>
      <h2>6. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:naeliasalas@gmail.com">naeliasalas@gmail.com</a>.</p>
    </section>


  </div>
</template>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  text-align: left;
  color: rgb(233, 225, 225);
  line-height: 1.6;
}

section {
  margin-bottom: 20px;
}

section ul {
  list-style-type: none;
  padding-left: 0;
}

section ul li:not(:last-child) {
  margin-bottom: 15px;
}

.back-button {
  padding: 8px 20px;
  border-radius: 10px;
  margin-left: 100px;
  margin-top: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s;
  background: linear-gradient(135deg, #00afea 0%, #0044cc 100%);
  color: #fff;
  border: none;
}

.back-button:hover {
  background: linear-gradient(135deg, #008fcc 0%, #003399 100%);
}

@media (max-width: 768px) {

  .privacy-policy{
    padding: 40px;
  }

  .back-button{
    margin-left: 45px;
    padding: 10px;
    font-size: 12px;
  }
}

</style>
