<script setup>
import { onMounted, ref, watch } from 'vue';
import emailjs from 'emailjs-com';


const name = ref('');
const email = ref('');
const message = ref('');
const gdprConsent = ref(false);

// emailJS
const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.VUE_APP_EMAILJS_TEMPLATE_ID;
const publicKey = process.env.VUE_APP_EMAILJS_PUBLIC_KEY;
const successMessage = ref('');

// Validation
const nameError = ref('')
const emailError = ref('')
const messageError = ref('')
const gdprError = ref('');

const validateEmail = (emailValue) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(emailValue);
};

onMounted(() => {
  emailjs.init(publicKey)
})

const handleSubmit = (e) => {
  e.preventDefault();

  nameError.value = '';
  emailError.value = '';
  messageError.value = '';
  gdprError.value = '';

  if (!name.value) {
    nameError.value = 'Name is required.';
  }
  if (!email.value || !validateEmail(email.value)) {
    emailError.value = 'Please enter a valid email address.';
  }
  if (!message.value) {
    messageError.value = 'Message is required.';
  }
  if (!gdprConsent.value) {
    gdprError.value = 'You must acknowledge the GDPR consent before submitting.';
  }

  if (!nameError.value && !emailError.value && !messageError.value && gdprConsent.value) {
    const form = {
      user_name: name.value,
      user_email: email.value,
      message: message.value,
    };

    emailjs.send(serviceID, templateID, form)
      .then(() => {
        name.value = '';
        email.value = '';
        message.value = '';
        gdprConsent.value = false;
        successMessage.value = 'Great, I have received your email!';
      }, (error) => {
        console.log('FAILED...', error.text);
        alert('Failed to send your message. Please try again later.');
      });
  }
};


watch([name, email, message], ([newName, newEmail, newMessage]) => {
  if(newName) {
    nameError.value = '';
  }
  if(newEmail && validateEmail(newEmail)){
    emailError.value = ''
  }
  if(newMessage){
    messageError.value = ''
  }
});
</script>

<template>
  <div class="two-column-section">
    <div class="contact-background"></div>

    <div class="left-column">
      <div class="contact-form">
        <h2>Say Hello</h2>
        <form
          novalidate
          @submit="handleSubmit"
        >
          <div class="form-group">
            <input
              id="name"
              v-model="name"
              type="text"
              placeholder="Enter your name"
            >
            <span
              v-if="nameError"
              class="error-message"
            >{{ nameError }}</span>
          </div>

          <div class="form-group">
            <input
              id="email"
              v-model="email"
              type="email"
              placeholder="Enter your email"
            >
            <span
              v-if="emailError"
              class="error-message"
            >{{ emailError }}</span>
          </div>

          <div class="form-group">
            <textarea
              id="message"
              v-model="message"
              placeholder="Enter your message"
            />
            <span
              v-if="messageError"
              class="error-message"
            >{{ messageError }}</span>
          </div>

          <div class="form-group">
          <span v-if="gdprError" class="error-message">{{ gdprError }}</span>
            <div class="gdpr-checkbox-container">
              <label class="gdpr-label">
                I acknowledge that I have read and agree to&nbsp;the&nbsp;
                <router-link to="/privacy-policy">GDPR compliance and privacy terms.</router-link>
                <input
                  type="checkbox"
                  v-model="gdprConsent"
                  id="gdprConsent"
                  class="gdpr-checkbox"
                >
              </label>
            </div>
          </div>

          <div class="button-email-container">
            <button type="submit">
              Hit me up!
            </button>
          </div>
          <p class="email-address">
            naeliasalas@gmail.com
          </p>
          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
        </form>
      </div>
    </div>
    <div class="right-column">
      <h1>Wanna talk and see what we can do for each other or together?</h1>
    </div>
    <div class="about-background"></div>
  </div>
</template>


<style scoped>
.two-column-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 50px;
}

.contact-background {
  position: absolute;
  bottom: -100px;
  left: -150px;
  width: 300px;
  height: 300px;
  background: rgba(0, 123, 255, 0.3);
  border-radius: 50%;
  filter: blur(80px);
}

.two-column-section > *:not(.contact-background) {
  position: relative;
}

.left-column,
.right-column {
  flex: 1;
  max-width: 60%;
  padding: 60px;
}

.contact-form {
  width: 100%;
}

.contact-form h2 {
  font-size: 34px;
  color: #ffffff;
  margin-bottom: 24px;
  text-align: left;
}

.form-group {
  margin-bottom: 24px;
}

input,
textarea {
  width: 100%;
  padding: 16px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #161a2c;
  color: #fff;
  outline: none;
}

input:focus,
textarea:focus {
  border-color: #00afea;
}

textarea {
  min-height: 100px;
}

button {
  padding: 16px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #00afea;
}

.button-email-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;
}

.email-address {
  color: #96A1C0;
  font-size: 18px;
  margin-top: 15px;
}

.right-column h1 {
  font-size: 48px;
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}

.success-message {
  margin-top: 20px;
  color: #78d97b;
  font-size: 14px;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
}

.gdpr-checkbox-container {
  display: flex;
  align-items: center;
}

.gdpr-label {
  font-size: 16px;
  color: #ccc;
  display: flex;
  align-items: center;
}

.gdpr-checkbox {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  transition: transform 0.2s ease;
}

.gdpr-label a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease;
}

.gdpr-label a:hover {
  color: #00afea;
}

.gdpr-checkbox:checked {
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .two-column-section {
    flex-direction: column;
    height: auto;
    align-items: center;
    text-align: center;
  }

  .left-column {
    max-width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-form h2, h1 {
    font-size: 16px;
  }

  .right-column {
    display: none;
  }
  button {
    margin-bottom: 10px;
  }

  .email-address {
    font-size: 16px;
    text-align: center;
    margin-top: 0;
  }

  .gdpr-label{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
  }

  .gdpr-checkbox {
  margin-top: 15px;
  }

  /* .contact-form h2 {
    font-size: 22px;
  } */

  input,
  textarea,
  button {
    width: 100%;
    font-size: 14px;
  }

  button {
    padding: 10px ;
  }

}
</style>




