
<script setup>
</script>

<template>
  <section class="skills-section">
    <div class="skills-background"></div>

    <h2>Skills</h2>
    <div class="skills-container">
      <div class="skills-column">
        <h3>Frontend</h3>
        <ul>
  <li>
    <i class="fab fa-html5"></i> HTML & CSS
    <span class="sub-skills">+ Sass, Bootstrap, Tailwind</span>
  </li>
  <li>
    <i class="fab fa-js-square"></i> TypeScript
    <span class="sub-skills">+ plain JavaScript</span>
  </li>
  <li>
    <i class="fab fa-vuejs"></i> Vue
    <span class="sub-skills">+ Piña/Vuex</span>
  </li>
  <li>
    <i class="fab fa-angular"></i> Ionic
  </li>
  <li>
    <i class="fab fa-react"></i> React.js
  </li>
  <li>
    <i class="fab fa-angular"></i> Angular
  </li>
</ul>
      </div>
      <div class="skills-column">
        <h3>Backend</h3>
        <ul>
  <li>
    <i class="fab fa-node-js"></i> Node.js/Express
    <span class="sub-skills">+ with TypeScript</span>
  </li>
  <li>
    <i class="fab fa-docker"></i> Docker
  </li>
  <li>
    <i class="fas fa-database"></i> MySQL & MongoDB
    <span class="sub-skills">+ REST, mongoose</span>
  </li>
  <li>
    <i class="fab fa-php"></i> PHP
    <span class="sub-skills">+ Laravel</span>
  </li>
  <li>
    <i class="fas fa-project-diagram"></i> REST & GraphQL
    <span class="sub-skills">+ minimal API</span>
  </li>
  <h3>Testing</h3>
  <li>
    <i></i> Cypress
  </li>
</ul>
      </div>
    </div>
    <a href="mailto:youremail@example.com">
      <button class="contact-button">Say hello <i class="fa fa-envelope"></i></button>
    </a>
  </section>
</template>

<style scoped>
.skills-section {
  position: relative;
  padding: 30px;
  background-color: #ffffff;
  color: #333;
  border-radius: 25px;
  max-width: 1200px;
  margin: 50px auto;
  overflow: hidden;
  margin-top: 50px;
}

.skills-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.skills-column {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  width: 45%;
}

.sub-skills {
  font-size: 0.9rem;
  color: #333;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #000000;
}

.skills-column ul {
  list-style: none;
  padding-left: 0;
}

.skills-column ul li {
  margin-bottom: 25px;
  font-size: 1.1rem;
}

.contact-button {
  background: linear-gradient(135deg, #00afea 0%, #0044cc 100%);
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 40px;
  transition: background 0.3s;
}

.contact-button:hover {
  background: linear-gradient(135deg, #008fcc 0%, #003399 100%);
}

.contact-button i {
  margin-left: 8px;
}

@media (max-width: 768px) {

  .skills-section{
    max-width: 90%;

  }
  .skills-container {
    flex-direction: column;
    align-items: center;
  }

  .skills-column {
    width: 100%;
  }

}
</style>
