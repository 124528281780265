<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { projects } from '../utils/projectData';
import Navigation from '../components/Navigation.vue';
import router from '../router';

const route = useRoute();
const projectId = parseInt(route.params.id, 10);
const project = computed(() => projects.value.find(p => p.id === projectId));

const backToProjectPage = () => {
  console.log('Navigating to projects');
  router.push({ path: '/', hash: '#projects' }).catch(err => {
    console.error('Navigation error:', err);
  });
};
</script>

<template>
  <Navigation />
  <div class="back-button-container">
    <button @click="backToProjectPage" class="back-btn">Back to all projects</button>
  </div>
  <div class="content-container">
    <div class="project-detail-wrapper" v-if="project">
      <div class="project-detail">
        <h1>{{ project.title }}</h1>
        <p>{{ project.description }}</p>
        <p>{{ project.subdescription }}</p>
        <div class="stack-section">
          <h2>Stack</h2>
          <ul>
            <li v-for="(stackItem, index) in project.stack" :key="index">{{ stackItem }}</li>
          </ul>
        </div>
        <a
          v-if="project.url"
          :href="project.url"
          target="_blank"
          rel="noopener noreferrer"
          class="project-link"
        >
          View Project
        </a>
      </div>
      <div class="project-images">
        <div v-if="project.images && project.images.length">
          <div class="image-grid">
            <img
              v-for="(image, index) in project.images"
              :key="index"
              :src="image"
              :alt="`Image ${index + 1}`"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Project not found.</p>
    </div>

  </div>
</template>

<style scoped>
.content-container {
  margin-top: 70px;
  padding: 40px;
  display: flex;
  justify-content: center;
}
/* .modal-footer {
  display: none;
} */
.project-detail-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  width: 100%;
  color: white;
}

.project-detail {
  flex: 1;
  max-width: 600px;
  margin-right: 20px;
  font-size: 18px;
}

.project-detail h1 {
  margin-bottom: 16px;
  font-size: 34px;
}

.project-detail p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.stack-section {
  margin-top: 24px;
}

.stack-section h2 {
  margin-bottom: 12px;
}

.stack-section ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
}

.stack-section li {
  margin-bottom: 8px;
}

.project-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00afea;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.project-link:hover {
  background-color: #008fc5;
}

.project-images {
  flex: 2;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-grid img {
  width: calc(33.333% - 6.66px);
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

.image-grid img:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
}

.back-button-container {
  position: relative;
  width: 100%;
}

.back-btn {
  position: absolute;
  right: 25px;
  bottom: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #00afea;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #008fc5;
}



/* @media (max-width: 1024px) {
  .image-grid img {
    width: calc(50% - 5px);
  }
} */

@media (max-width: 768px) {
  .project-detail-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .project-detail {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .project-images {
    margin-top: 20px;
    width: 100%;
  }

  .image-grid img {
    width: 100%;
  }
}
</style>

