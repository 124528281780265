import axios from 'axios'

const WORDPRESS_BASE_URL = 'https://public-api.wordpress.com/wp/v2/sites/naeliasalas.wordpress.com';

export default {
async getPosts(perPage = 100) {
  try {
    const response = await axios.get(`${WORDPRESS_BASE_URL}/posts`, {
      params: {
        per_page: perPage,
        _embed: true,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
},

  async getPostById(id) {
    try {
      const response = await axios.get(`${WORDPRESS_BASE_URL}/posts/${id}`);
    return response.data;
  }catch (error) {
    console.error(`Error fetching post ${id}:`, error);
    throw error;
  }
  }
}
