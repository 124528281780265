<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import ProjectModal from '../components/ProjectModal.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper/modules';
import { projects } from '../utils/projectData';

import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const showModal = ref(false);
const selectedProject = ref(null);
const isMobile = ref(window.innerWidth < 768);
const router = useRouter();

const openModal = (project) => {
  isMobile.value ? goToProjectPage(project.id) : (selectedProject.value = project, showModal.value = true);
};

const goToProjectPage = (id) => router.push({ name: 'ProjectDetailView', params: { id } });

onMounted(() => window.addEventListener('resize', () => (isMobile.value = window.innerWidth < 768)));


</script>

<template>
  <h1 class="project-title">Projects</h1>
  <div class="slider-container">
    <Swiper
      v-if="projects.length"
      :slides-per-view="isMobile ? 1 : 3"
      :space-between="60"
      :navigation="true"
      :modules="[Pagination, Navigation]"
    >
      <SwiperSlide
        v-for="project in projects"
        :key="project.id"
      >
        <div class="project-content" @click="openModal(project)">
          <img :src="project.image" :alt="project.title" class="project-image" />
          <div class="project-overlay">
            <h2>{{ project.title }}</h2>
            <p>{{ project.subtitle }}</p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <div class="swiper-pagination"></div>

    <ProjectModal
      v-if="showModal && !isMobile"
      :project="selectedProject"
      @close="showModal = false"
    >
      <div class="modal-footer">
        <button
          @click="goToProjectPage(selectedProject.id)"
          class="full-page-btn"
        >
          See Full Page
        </button>
      </div>
    </ProjectModal>
  </div>
</template>

<style lang="scss" scoped>

.project-title{
  color: white
}
.slider-container {
  padding-top: 60px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out;
}

.swiper-slide img:hover {
  transform: scale(1.05);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 18px;
  color: white;
}

.swiper-pagination-bullet {
  background-color: rgb(255, 255, 255);
}

.project-content {
  position: relative;
  background-color: #161a2c;
  color: white;
  border: 2px solid #b9bbbe;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  cursor: pointer;
}

.project-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-content:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  cursor: pointer;

}

.project-content:hover img {
  transform: scale(1.1);
  cursor: pointer;

}

.project-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
  z-index: 10;
}

.project-content:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.project-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 20;
  color: white;
  cursor: pointer;
}

.project-content:hover .project-overlay {
  opacity: 1;
  cursor: pointer;
}

.project-overlay h2 {
  margin: 10px 0;
  font-size: 32px;
  cursor: pointer;
}

.project-overlay p {
  margin: 0;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .slider-item {
    width: 100%;
  }

  .project-content {
    height: 300px;
  }

  .arrow-btn {
    display: none;
  }
}
</style>
