<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import wordpressService from '@/services/wordpressService';
import DOMPurify from 'dompurify';
import Navigation from './Navigation.vue';


export default {
  name: 'BlogList',
  components: {
    Navigation,
  },
  setup() {
    const posts = ref([]);
    const loading = ref(true);
    const error = ref(false);
    const router = useRouter();

    const fetchPosts = async () => {
    loading.value = true;
    error.value = false;

  try {
    const postsData = await wordpressService.getPosts();
    posts.value = postsData.map((post) => {
      const imageUrl = post.jetpack_featured_media_url || '';
      return {
        id: post.id,
        title: DOMPurify.sanitize(post.title.rendered),
        date: new Date(post.date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        category: post.categories[0] || 'Uncategorized',
        imageUrl,
      };
    });
  } catch (err) {
    console.error('Error fetching posts:', err);
    error.value = true;
  } finally {
    loading.value = false;
  }
};

const goBack = () => {
      router.push({ name: 'Home' });
    };

    onMounted(() => {
      fetchPosts();
    });

    return {
      posts,
      loading,
      error,
      goBack
    };
  },
};
</script>


<template>
  <Navigation />

  <div class="blog-list">
    <!-- <button class="back-button" @click="goBack">← Back to Home</button> -->

    <h1 class="blog-title">Blog Posts</h1>
    <div v-if="loading" class="loading">Loading posts...</div>
    <div v-else-if="error" class="error">Error loading posts.</div>

    <section v-else class="post-list">
      <div
        v-for="(post, index) in posts"
        :key="post.id"
        class="post-item"
        :class="`color-${index % 4}`"
      >
        <div class="thumbnail">
            <img v-if="post.imageUrl" :src="post.imageUrl " alt="Blog Post Image" class="thumbnail-image" />
        </div>

        <div class="post-content">
          <router-link
            :to="{ name: 'BlogPost', params: { id: post.id }}"
            class="post-title"
          >
            <span v-html="post.title"></span>
          </router-link>

          <div class="post-meta">
            <span class="date">{{ post.date }}</span>
          </div>
        </div>

        <div class="color-line"></div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.blog-list {
  color: #fff;
  font-family: Arial, sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-title {
  color: #dee7d8;
  font-size: 2rem;
  text-align: center;
  margin: 2rem 0;
}

.post-list {
  width: 100%;
  max-width: 1200px;
}

.post-item {
  display: grid;
  grid-template-columns: 220px 1fr auto;
  grid-template-areas:
    'thumbnail content color-line';
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0;
  position: relative;
}

.thumbnail {
  grid-area: thumbnail;
  width: 220px;
  height: 150px;
  background-color: #333;
  border-radius: 4px;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.post-content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.post-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: inherit;
}

.post-title:hover {
  color: #76c7b7;
}

.post-meta {
  font-size: 0.875rem;
  color: #888;
}

.date {
  color: #888;
}

.color-line {
  grid-area: color-line;
  width: 5px;
  height: 100%;
  background-color: transparent;
}

.color-0 .post-title,
.color-0 .date {
  color: #84ff90;
}

.color-0 .color-line {
  background-color: #84ff90;
}

.color-1 .post-title,
.color-1 .date {
  color: #7ef056;
}

.color-1 .color-line {
  background-color: #7ef056;
}

.color-2 .post-title,
.color-2 .date {
  color: #f9de2f;
}

.color-2 .color-line {
  background-color: #f9de2f;
}

.color-3 .post-title,
.color-3 .date {
  color: #ffae42;
}

.color-3 .color-line {
  background-color: #ffae42;
}

.back-button {
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 40px;
  transition: background 0.3s;
  background: linear-gradient(135deg, #00afea 0%, #0044cc 100%);
  color: #fff;
  border: none;
}

.back-button:hover {
  background: linear-gradient(135deg, #008fcc 0%, #003399 100%);
}

@media (max-width: 768px) {
  .post-item {
    grid-template-columns: 150px 1fr auto;
    grid-template-areas:
      'thumbnail content color-line';
  }

  .thumbnail {
    width: 150px;
    height: 100px;
  }

  .post-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .post-item {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'content color-line'
      'thumbnail thumbnail';
    gap: 0.5rem;
  }

  .thumbnail {
    grid-area: thumbnail;
    width: 100%;
    height: auto;
  }

  .post-content {
    padding-right: 0;
  }

  .post-title {
    font-size: 1.2rem;
  }

  .color-line {
    width: 100%;
    height: 5px;
  }
}
</style>
